<template>
  <fm-modal class="c-log" :mask-closable="false" :value="openDialogLog" width="1000px" v-if="openDialogLog" @cancel="handleClose2">
    <fm-title title-text="变更记录"
      :title-menus="[{key: 'first', label: '新增首次注册'}, {key: 'goon', label: '新增延续注册'}, {key: 'change', label: '新增变更注册'}]"
      @clickTitleMenu="clickTitleMenu">
    </fm-title>
    <div class="zyzs-bjjl">
      <div v-for="item in updateLogList" :key="item.id">
        <div class="jl-item" v-if="item.type === 'change'">
          <fm-title title-text="变更注册" @clickTitleMenu="clickTitleMenuLog($event, item)" :title-menus="[{key: 'edit', label: '修改'}, {key: 'del', label: '删除'}]"></fm-title>
          <div class="log-info">
            <div>变更日期:</div>
            <span>{{item.changeDate}}</span>
          </div>
          <div class="log-info">
            <div>变更事项:</div>
            <span>{{item.changeContent}}</span>
          </div>
        </div>
        <div class="jl-item" v-if="item.type === 'goon'">
          <fm-title title-text="延续注册" @clickTitleMenu="clickTitleMenuLog($event, item)" :title-menus="[{key: 'edit', label: '修改'}, {key: 'del', label: '删除'}]"></fm-title>
          <div class="log-info">
            <div>注册日期:</div>
            <span>{{item.registDate}}</span>
          </div>
          <div class="log-info">
            <div>注册有效期至:</div>
            <span>{{item.registEndDate}}</span>
          </div>
        </div>
        <div class="jl-item" v-if="item.type === 'first'">
          <fm-title title-text="首次注册" @clickTitleMenu="clickTitleMenuLog($event, item)" :title-menus="[{key: 'edit', label: '修改'}, {key: 'del', label: '删除'}]"></fm-title>
          <div class="log-info">
            <div>注册日期:</div>
            <span>{{item.registDate}}</span>
          </div>
          <div class="log-info">
            <div>注册有效期至:</div>
            <span>{{item.registEndDate}}</span>
          </div>
        </div>
      </div>
    </div>
    <fm-form-dialog
      :form-title="logType === 'goon' ? '延续注册' : (logType === 'change' ? '变更注册' : '首次注册')"
      :open-dialog.sync="openDialog"
      :form-parms="formParms"
      :old-data="hisLogData"
      :mask-closable="false"
      label-alone
      label-align="left"
      form-width="600px"
      @formSubmit="formSubmit"
      @handleClose="openDialog = false">
    </fm-form-dialog>
  </fm-modal>
</template>

<script>
import {
  workerCertificateRequest as request
} from '@/api'

import {
  dateOperating
} from '@/fmlib'

export default {
  created () {
    this.loadData()
  },
  props: {
    workerCertificateId: {
      type: Number
    },
    openDialogLog: {
      type: Boolean
    }
  },
  computed: {
    formParms () {
      let yx = [{
        type: 'datePicker',
        label: '注册日期',
        key: 'registDate'
      },
      {
        type: 'datePicker',
        label: '注册有效期至',
        key: 'registEndDate'
      }]
      let bg = [{
        type: 'datePicker',
        label: '注册日期',
        key: 'changeDate'
      },
      {
        type: 'input',
        label: '变更事项',
        key: 'changeContent'
      }]
      return this.logType === 'change' ? bg : yx
    }
  },
  methods: {
    async formSubmit (parm, resolve) {
      for (let key in parm) {
        if (parm[key] instanceof Date) {
          parm[key] = dateOperating.computeDay({days: 0, date: parm[key], format: 'yy-mm-dd'})
        }
      }
      parm.type = this.logType
      let newData = []
      if (parm.id) {
        this.updateLogList.forEach((v) => {
          if (v.id === parm.id) {
            newData.push(parm)
          } else {
            newData.push(v)
          }
        })
      } else {
        newData = this.updateLogList.map(v => v)
        parm.id = new Date().getTime()
        newData.push(parm)
      }
      newData.sort((a, b) => {
        let datea = new Date(a.registDate || a.changeDate)
        let dateb = new Date(b.registDate || b.changeDate)
        return dateb - datea
      })
      this.updateLogList = newData
      let parm2 = this.onChange(JSON.stringify(this.updateLogList), this.chooseData)
      await request.update(this.choose.id, parm2)
      this.openDialog = false
      this.loadData()
      resolve()
    },
    handleClose2 () {
      this.$emit('handleClose')
    },
    async clickTitleMenuLog (key, data) {
      this.logType = data.type
      if (key === 'edit') {
        this.hisLogData = Object.assign({}, data)
        this.openDialog = true
      } else {
        const result = await this.$dialog.confirm({title: '系统提示', content: '确定删除该' + (this.logType === 'change' ? '变更注册' : (this.logType === 'goon' ? '延续注册' : '首次注册')) + '吗?'})
        if (!result) {
          return
        }
        this.updateLogList = this.updateLogList.filter(v => v.id !== data.id)
        let parm2 = this.onChange(JSON.stringify(this.updateLogList), this.chooseData)
        await request.update(this.choose.id, parm2)
        this.openDialog = false
        this.loadData()
      }
    },
    onChange (value, chooseData) {
      let data = {
        updateLog: value
      }
      let datas = value ? JSON.parse(value) : []
      datas = datas.filter(v => ['goon', 'first'].includes(v.type))
      if (datas.length === 0) {
        data['hireStartDate'] = chooseData['getDate']
        data['hireEndDate'] = null
      } else {
        data['hireStartDate'] = datas[0].registDate
        data['hireEndDate'] = datas[0].registEndDate
      }
      for (let key in data) {
        if (data[key] instanceof Date) {
          data[key] = dateOperating.computeDay({days: 0, date: data[key], format: 'yy-mm-dd'})
        }
      }
      console.log('onChange', data)
      return data
    },
    clickTitleMenu (key) {
      this.hisLogData = null
      this.logType = key
      this.openDialog = true
    },
    async loadData () {
      let parm = {
        type: 'doctor_pra',
        id: this.workerCertificateId
      }
      let data = await request.get(parm)
      if (data && data.length > 0) {
        data = data[0]
        data.updateLogList = data.updateLog ? JSON.parse(data.updateLog) : []
      }
      this.choose = data
      this.updateLogList = this.choose.updateLogList || []
    }
  },
  data () {
    return {
      logType: '',
      hisLogData: null,
      updateLogList: [],
      choose: null,
      openDialog: false
    }
  }
}
</script>

<style lang="less">
.c-log {
  .fm-form-content {
      padding: 0 20px !important;
  }
}
</style>

<style lang="less" scoped>
.jl-item {
  border: 1px solid #dcdee2;
  border-radius: 3px;
  padding: 8px 16px;
  margin: 1rem 0;
}
.log-info {
  display: flex;
  min-height: 2rem;
  align-items: center;
  div {
    padding-right: 1rem;
  }
}
.log-info-t {
  color: #515a6e;
}
</style>